import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "getSelectedCourse",
      "getPreviousSection",
      "getCurrentSection",
      "getCurrentSequence",
      "getPreviousSequence",
      "isLastSequence",
      "isLastUnit",
      "isAllSectionsCompleted"
    ])
  },
  methods: {
    handleOnFinish() {
      if (this.getSelectedCourse.course_completed) {
        this.$emit("handleCourseCompletion");
        this.onCourseComplete();
      } else {
        // show loader
        this.$store.commit("SET_SHOW_FULL_PAGE_LOADER", true);
        // get the completion status from server
        this.$store
          .dispatch("getACourse", {
            course_id: this.$route.params.id,
            version: "v2"
          })
          .then(() => {
            // check if all sections are completed
            if (this.isAllSectionsCompleted === true) {
              // in case if course completion certifcate is not
              // generated/issued
              if (!this.getSelectedCourse.course_completed) {
                this.$store
                  .dispatch("postCourseCertificate", this.getSelectedCourse.id)
                  .then(() => {
                    this.$emit("handleCourseCompletion");
                    this.onCourseComplete();
                  });
              } else {
                this.$emit("handleCourseCompletion");
                this.onCourseComplete();
              }
            } else {
              this.onCourseComplete();
              // hide the loader
              this.$store.commit("SET_SHOW_FULL_PAGE_LOADER", false);
              this.$bvModal.show("unfinish-modal");
            }
          });
      }
    },
    onCourseComplete() {
      let section = this.getCurrentSection;
      if (this._isSectionHasCredential(section)) {
        this.issueCredential(section.id)
          .then(() => {
            // hide the loader
            this.$store.commit("SET_SHOW_FULL_PAGE_LOADER", false);
          })
          .catch(() => {
            // hide the loader
            this.$store.commit("SET_SHOW_FULL_PAGE_LOADER", false);
          });
      } else {
        // hide the loader
        this.$store.commit("SET_SHOW_FULL_PAGE_LOADER", false);
      }
    },
    issueCredential(sectionID) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("createCredential", {
            courseId: this.getSelectedCourse.id,
            sectionId: sectionID
          })
          .then(() => {
            // shows tooltip if the user has not completed the course
            // when user completes the course we show a modal with course completion certificate
            if (
              !(this.isLastSequence && this.getSelectedCourse.course_completed)
            ) {
              this.$bvToast.show("my-toast");
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    _isSectionHasCredential(section) {
      let hasCredential = false;
      if (
        section &&
        !section.is_microcredential_awarded &&
        section.award_type
      ) {
        if (section.award_type === "COMPLETE_SECTION" && section.complete) {
          hasCredential = true;
        } else if (section.award_type === "PASS_ASSESSMENT") {
          // check if all the graded sequences are complete
          if (
            section.sequences.every(seq =>
              seq.is_graded && seq.is_graded === true ? seq.complete : true
            )
          ) {
            hasCredential = true;
          }
        }
      }
      return hasCredential;
    }
  }
};
